.content {
  padding-bottom: 0;
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.headlineButtons {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 12px;
  align-items:flex-start;
}

.linkTag {
  color: var(--color-gray-700) !important;
}

.profileAddress {
  margin-top: 8px;
  color: var(--color-gray-500);
}

.stats {
  padding: 35px;
  border: 1px solid var(--color-gray-400);
  border-radius: var(--border-radius-secondary);
  margin-top: 32px;
}

.statsLayout {
  display: grid;
  grid-template-columns: repeat(7, auto);
  align-items: start;
  gap: 18px;
}

.cards {
  margin-top: 72px;
}