body {
  --color-blue-700: #0F4FD1;
  --color-blue-600: #1861F5;
  --color-blue-500: #3475F9;
  --color-blue-400: #9ABBFF;
  --color-blue-300: #DDE8FF;
  --color-blue-200: #EBF1FF;
  --color-blue-100: #FAFBFF;

  --color-gray-700: #292929;
  --color-gray-600: #7D7D7D;
  --color-gray-500: #AAAAAA;
  --color-gray-400: #E6E6E6;
  --color-gray-300: #F6F6F6;
  --color-gray-200: #FAFAFA;
  --color-gray-100: #FBFBFB;

  --color-red-500: #F86565;
  --color-red-400: #FF7676;
  --color-red-300: #FFA4A4;
  --color-red-200: #FFD1D1;
  --color-red-100: #FFFBFB;

  --color-black: #000000;
  --color-black-06: rgba(0, 0, 0, 0.6);
  --color-white: #FFFFFF;

  --border-radius-primary: 4px;
  --border-radius-secondary: 8px;
  
  --transition-primary: 250ms;
  --transition-secondary: 450ms;
}